import EditIcon from '@material-ui/icons/Edit';

const { TableRow, TableCell, IconButton, makeStyles } = require('@material-ui/core');
const { Delete } = require('@material-ui/icons');

const Category = ({ category: { id, name }, onOpenEditCategoryModal, onOpenDeleteCategoryModal }) => {
    const useStyles = makeStyles(() => ({
        categoryName: {
            overflowWrap: 'anywhere',
        },
    }));

    const classes = useStyles();

    return (
        <TableRow aria-label="category">
            <TableCell className={classes.categoryName}>{name}</TableCell>
            <TableCell>
                <IconButton onClick={onOpenEditCategoryModal(id)} aria-label={`Edit category-${id}`}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={onOpenDeleteCategoryModal(id)} aria-label={`Delete category-${id}`}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default Category;
