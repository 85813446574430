import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        show: false,
    },
    reducers: {
        openMenu: state => {
            state.show = true;
        },
        closeMenu: state => {
            state.show = false;
        },
    },
});

export const { openMenu, closeMenu } = menuSlice.actions;

export default menuSlice.reducer;
