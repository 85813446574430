import { createSelector } from '@reduxjs/toolkit';

export const showMenu = state => state.menu.show;
export const getLedgerItems = state => state.ledgerItems.ledgerItems;
export const getLedgerItemsSummary = state => state.ledgerItems.summary;
export const showLoader = state => state.ledgerItems.showLoader;
export const showAddMultipleItemsModal = state => state.multipleItemsAdder.show;
export const showErrorSnackbar = state => state.errorSnackbar.show;
export const getAdvancedFilters = state => state.ledgerItems.advancedFilters;
export const getStartDate = state => state.ledgerItems.baseFilters.startDate;
export const getEndDate = state => state.ledgerItems.baseFilters.endDate;
export const showSearchModal = state => state.search.show;
export const getCategories = state => state.categories.categories;
export const showCategoriesLoader = state => state.categories.showLoader;
export const getLoggedIn = state => state.authentication.loggedIn;
export const getCategoriesSelectBox = createSelector(getCategories, selectorValue => {
    return Object.values(selectorValue).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
});
export const getCategoriesSortedAlphabetically = createSelector(getCategories, selectorValue => {
    return Object.entries(selectorValue).sort(([, category1], [, category2]) => {
        if (category1.name.toLowerCase() < category2.name.toLowerCase()) {
            return -1;
        }

        if (category1.name.toLowerCase() > category2.name.toLowerCase()) {
            return 1;
        }
        return 0;
    });
});
export const getAdvancedFiltersWithMappedValues = createSelector(
    getAdvancedFilters,
    getCategories,
    (advancedFilters, categories) => {
        const selectedCategoriesNames = advancedFilters.categoryIds
            ?.map(element => categories[element]?.name)
            .join(', ');

        const mappedValues = {
            ...advancedFilters,
            ...(selectedCategoriesNames ? { categoryIds: selectedCategoriesNames } : ''),
        };

        return mappedValues;
    }
);
