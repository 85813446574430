import { List, ListItem, makeStyles } from '@material-ui/core';

const AddMultipleItemsModalSummary = ({ totalExpenses, totalIncomes }) => {
    const useStyles = makeStyles(() => ({
        total: {
            display: 'flex',
            width: '25%',
        },
    }));

    const classes = useStyles();

    return (
        <List className={classes.total} dense>
            <ListItem data-testId="total-income">Total incomes: {totalIncomes}</ListItem>
            <ListItem data-testId="total-expense">Total expenses: {totalExpenses}</ListItem>
        </List>
    );
};

export default AddMultipleItemsModalSummary;
