import { createAsyncThunk } from '@reduxjs/toolkit';
import { login, logout, refreshToken } from 'rest/RestApiRepository';
import {
    createLedgerItemsFilter,
    deleteCategory,
    deleteLedgerItem as deleteById,
    getAllCategories,
    getLedgerItem as getById,
    getLedgerItems,
    loadData,
    saveCategory,
    saveLedgerItem,
} from 'services/LedgerItemsService';

export const loadInitialData = createAsyncThunk('ledgerItems/loadInitialData', async () => {
    const response = await loadData();
    response.forEach(r => (r.date = r.date.getTime()));
    return response;
});

export const loadItemsData = createAsyncThunk(
    'ledgerItems/loadItemsData',
    async (arg, { getState, rejectWithValue }) => {
        try {
            const ledgerItems = getState().ledgerItems;

            const filter = createLedgerItemsFilter(ledgerItems.baseFilters, ledgerItems.advancedFilters);

            const response = await getLedgerItems(filter);
            response.ledgerItems.forEach(r => (r.date = Date.parse(r.date)));
            return response;
        } catch (err) {
            return rejectWithValue(prepareRejectPayload(err.response));
        }
    }
);

export const saveLedgerItems = createAsyncThunk('ledgerItems/saveLedgerItems', async (items, { rejectWithValue }) => {
    try {
        const response = await saveLedgerItem(items);
        return response.ledgerItems;
    } catch (err) {
        return rejectWithValue(prepareRejectPayload(err.response));
    }
});

export const getLedgerItem = createAsyncThunk(
    'ledgerItems/getLedgerItem',
    async (ledgerItemId, { rejectWithValue }) => {
        try {
            return await getById(ledgerItemId);
        } catch (err) {
            return rejectWithValue(prepareRejectPayload(err.response));
        }
    }
);

export const deleteLedgerItem = createAsyncThunk(
    'ledgerItems/deleteLedgerItem',
    async (ledgerItemId, { rejectWithValue }) => {
        try {
            return await deleteById(ledgerItemId);
        } catch (err) {
            return rejectWithValue(prepareRejectPayload(err.response));
        }
    }
);

export const loadCategories = createAsyncThunk('categories/loadCategories', async (arg, { rejectWithValue }) => {
    try {
        const response = await getAllCategories();
        return response.categories;
    } catch (err) {
        return rejectWithValue(prepareRejectPayload(err.response));
    }
});

export const saveCategoryThunk = createAsyncThunk('categories/saveCategory', async (category, { rejectWithValue }) => {
    try {
        return await saveCategory(category);
    } catch (err) {
        return rejectWithValue(prepareRejectPayload(err.response));
    }
});

export const deleteCategoryThunk = createAsyncThunk(
    'categories/deleteCategory',
    async (categoryId, { rejectWithValue }) => {
        try {
            return await deleteCategory(categoryId);
        } catch (err) {
            return rejectWithValue(prepareRejectPayload(err.response));
        }
    }
);

export const loginThunk = createAsyncThunk('authentication/login', async (credentials, { rejectWithValue }) => {
    try {
        return await login(credentials);
    } catch (err) {
        return rejectWithValue(prepareRejectPayload(err.response));
    }
});

export const logoutThunk = createAsyncThunk('authentication/logout', async (payload, { rejectWithValue }) => {
    try {
        return await logout();
    } catch (err) {
        return rejectWithValue(prepareRejectPayload(err.response));
    }
});

export const refreshTokenThunk = createAsyncThunk(
    'authentication/refreshToken',
    async (payload, { rejectWithValue }) => {
        try {
            return await refreshToken();
        } catch (err) {
            return rejectWithValue(prepareRejectPayload(err.response));
        }
    }
);

const prepareRejectPayload = response => {
    const rejectPayload = {
        httpStatus: null,
        httpStatusText: null,
        body: null,
    };

    rejectPayload.httpStatus = response.status;
    rejectPayload.httpStatusText = response.statusText;
    rejectPayload.body = response.body;

    return rejectPayload;
};
