import { FormControl, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { isFulfilled } from '@reduxjs/toolkit';
import { Defaults } from 'const/Defaults';
import { ItemFields } from 'const/ItemFields';
import { LedgerItemTypes } from 'const/LedgerItemTypes';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getCategories } from 'store/selectors';
import { getLedgerItem, loadItemsData, saveLedgerItems } from 'store/thunks';
import { roundToTwoDecimalPlaces } from 'utils/NumberUtils';

import InputLedgerItemFormInput from './InputLedgerItemFormInput';

const InputLedgerItem = () => {
    const categories = useSelector(getCategories);
    const [categoriesInputValue, setCategoriesInputValue] = useState('');

    const [inputData, setInputData] = useState({
        date: moment().format(Defaults.DATE_FORMAT),
        vendor: '',
        description: '',
        type: LedgerItemTypes.EXPENSE,
        count: '',
        pricePerUnit: '',
        sum: '',
        categoryId: Defaults.CATEGORY_ID,
    });
    const history = useHistory();

    const dispatch = useDispatch();

    const handleSubmit = e => {
        e.preventDefault();
        handleSaveLedgerItem(inputData);
    };

    const onChange = e => {
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
        });
    };

    const onBlurCount = e => {
        setInputData({
            ...inputData,
            [ItemFields.COUNT.name]: e.target.value ? Number(e.target.value) : '',
            [ItemFields.SUM.name]: roundToTwoDecimalPlaces(e.target.value * inputData.pricePerUnit),
        });
    };

    const onBlurPricePerUnit = e => {
        const pricePerUnitRounded = e.target.value ? Number(roundToTwoDecimalPlaces(e.target.value)) : '';
        setInputData({
            ...inputData,
            [ItemFields.PRICE_PER_UNIT.name]: pricePerUnitRounded,
            [ItemFields.SUM.name]: roundToTwoDecimalPlaces(pricePerUnitRounded * inputData.count),
        });
    };

    const handleSaveLedgerItem = async ledgerItem => {
        const saveResult = await dispatch(saveLedgerItems([ledgerItem]));

        if (isFulfilled(saveResult)) {
            dispatch(loadItemsData());
            history.push('/');
        }
    };

    const onInputChangeCategory = (event, value) => {
        if (event) {
            setCategoriesInputValue(value);
        }
    };

    const onChangeCategory = (event, category) => {
        if (category) {
            setInputData({
                ...inputData,
                categoryId: category.id,
            });
        }
    };

    const { ledgerItemId } = useParams();

    useEffect(() => {
        let isActive = true;

        const loadLedgerItem = async () => {
            if (ledgerItemId && isActive) {
                const ledgerItem = await dispatch(getLedgerItem(ledgerItemId));
                isActive && isFulfilled(ledgerItem) && setInputData(ledgerItem.payload);
            }
        };

        loadLedgerItem();

        return () => (isActive = false);
    }, [ledgerItemId, dispatch]);

    useEffect(() => {
        setCategoriesInputValue(categories[inputData.categoryId]?.name);
    }, [categories, inputData.categoryId]);

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                {ledgerItemId ? 'Edit ledger item' : 'New ledger item'}
            </Typography>

            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.DATE.label}
                        value={inputData.date}
                        onChange={onChange}
                        type="date"
                        name={ItemFields.DATE.name}
                        required={true}
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.VENDOR.label}
                        value={inputData.vendor}
                        onChange={onChange}
                        name={ItemFields.VENDOR.name}
                        autoFocus
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.DESCRIPTION.label}
                        value={inputData.description}
                        onChange={onChange}
                        name={ItemFields.DESCRIPTION.name}
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.TYPE.label}
                        value={inputData.type}
                        onChange={onChange}
                        name={ItemFields.TYPE.name}
                        selectOptions={LedgerItemTypes}
                        required
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.CATEGORY.label}
                        value={inputData.categoryId}
                        onChange={onChangeCategory}
                        name={ItemFields.CATEGORY.name}
                        selectOptions={categories}
                        categoriesInputValue={categoriesInputValue}
                        onInputChange={onInputChangeCategory}
                        isAutoComplete
                        autoCompleteGetOptionLabel={option => option.name || ''}
                        autoCompleteGetOptionSelected={(option, value) => option.id === value}
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.COUNT.label}
                        value={inputData.count}
                        onChange={onChange}
                        onBlur={onBlurCount}
                        name={ItemFields.COUNT.name}
                        type="number"
                        required
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.PRICE_PER_UNIT.label}
                        value={inputData.pricePerUnit}
                        onChange={onChange}
                        onBlur={onBlurPricePerUnit}
                        name={ItemFields.PRICE_PER_UNIT.name}
                        type="number"
                        required
                    />

                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.SUM.label}
                        value={inputData.sum}
                        name={ItemFields.SUM.name}
                        type="number"
                    />

                    <FormControl margin="dense">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            role="saveButton">
                            Save
                        </Button>
                    </FormControl>
                </FormControl>
            </form>
        </>
    );
};

export default InputLedgerItem;
