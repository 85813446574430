import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';

const BalanceSummary = ({ ledgerItemsSummary }) => {
    const [summary, setSummary] = useState(ledgerItemsSummary);

    const useStyles = makeStyles(theme => ({
        categoryNameCell: {
            wordBreak: 'break-all',
        },
    }));

    const classes = useStyles();

    useEffect(() => {
        setSummary(ledgerItemsSummary);
    }, [ledgerItemsSummary]);

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Summary
            </Typography>

            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Incomes</TableCell>
                        <TableCell>Expenses</TableCell>
                        <TableCell>Balance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {summary &&
                        Object.keys(summary).map(elem => {
                            return (
                                <TableRow key={elem}>
                                    <TableCell className={classes.categoryNameCell}>
                                        {summary[elem].categoryName}
                                    </TableCell>
                                    <TableCell>{summary[elem].incomes}</TableCell>
                                    <TableCell>{summary[elem].expenses}</TableCell>
                                    <TableCell>{summary[elem].balance}</TableCell>
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </>
    );
};

export default BalanceSummary;
