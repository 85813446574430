import { DatePicker } from '@material-ui/pickers';
import { Defaults } from 'const/Defaults';

const DateFilter = ({ value, onChange, label, fullWidth }) => {
    const applyFiltering = newValue => {
        onChange(newValue ? newValue.startOf('day').toDate().getTime() : null);
    };

    return (
        <DatePicker
            views={['year', 'month', 'date']}
            value={value}
            onChange={applyFiltering}
            format={Defaults.DATE_FORMAT}
            showTodayButton
            clearable
            label={label}
            fullWidth={fullWidth}
        />
    );
};

export default DateFilter;
