import { refreshTokenAction } from 'store/reducers/Authentication';
import { bootstrapAction, setAdvancedFilters, setFiltersAction } from 'store/reducers/LedgerItems';
import { loadCategories, loadItemsData, refreshTokenThunk } from 'store/thunks';

const LedgerItemsMiddleware = store => next => action => {
    if (setFiltersAction.match(action)) {
        store.dispatch(setAdvancedFilters(action.payload));
        store.dispatch(loadItemsData());
    }
    if (bootstrapAction.match(action)) {
        store.dispatch(loadItemsData());
        store.dispatch(loadCategories());
    }
    if (refreshTokenAction.match(action)) {
        store.dispatch(refreshTokenThunk(action.payload));
    }

    return next(action);
};

export default LedgerItemsMiddleware;
