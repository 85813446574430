import { createSlice, isRejectedWithValue } from '@reduxjs/toolkit';

const EXCLUDED_REJECTED_ACTIONS = ['authentication/refreshToken/rejected', 'authentication/login/rejected'];

export const errorSnackbarSlice = createSlice({
    name: 'errorSnackbar',
    initialState: {
        show: false,
    },
    reducers: {
        openErrorSnackbar: state => {
            state.show = true;
        },
        closeErrorSnackbar: state => {
            state.show = false;
        },
    },
    extraReducers(builder) {
        builder.addMatcher(isRejectedWithValue, (state, action) => {
            if (isExcludedRejectedAction(action)) {
                state.show = false;
            } else {
                state.show = true;
            }
        });
    },
});

const isExcludedRejectedAction = action => {
    return EXCLUDED_REJECTED_ACTIONS.find(e => action.type === e);
};

export const { openErrorSnackbar, closeErrorSnackbar } = errorSnackbarSlice.actions;
export default errorSnackbarSlice.reducer;
