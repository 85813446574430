import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorSnackbar } from 'store/reducers/ErrorSnackbar';
import { showErrorSnackbar } from 'store/selectors';

const ErrorSnackbar = () => {
    const isShowingSnackbar = useSelector(showErrorSnackbar);

    const dispatch = useDispatch();

    return (
        <Snackbar
            open={isShowingSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            message="An error has occured, please try again"
            onClose={() => dispatch(closeErrorSnackbar())}>
            <MuiAlert elevation={6} variant="filled" severity="error">
                An error has occured, please try again
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => dispatch(closeErrorSnackbar())}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </MuiAlert>
        </Snackbar>
    );
};

export default ErrorSnackbar;
