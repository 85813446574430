import { Defaults } from 'const/Defaults';
import { count, getAll, getByDateRange } from 'indexedDb/LedgerItemsRepository';
import moment from 'moment';
import {
    deleteById,
    executeDeleteCategory,
    executeSaveCategory,
    getById,
    getCategories,
    getLedgerItemsFiltered,
    save,
} from 'rest/RestApiRepository';
import { isNullOrEmpty } from 'utils/StringUtils';

import { populateDatabaseWithSampleData } from './SampleResponseService';

export const countLedgerItems = () => {
    return count();
};

export const getAllLedgerItems = () => {
    return getAll();
};

export const addAllLedgerItems = ledgerItems => {
    return save(ledgerItems);
};

export const getLedgerItem = id => {
    return getById(id);
};

export const saveLedgerItem = ledgerItem => {
    return save(ledgerItem);
};

export const deleteLedgerItem = id => {
    return deleteById(id);
};

export const getLedgerItemsByDateRange = (startDate, endDate) => {
    return getByDateRange(startDate, endDate);
};

export const loadData = async () => {
    await populateDatabaseWithSampleData();

    const startDate = moment().startOf('month').toDate();
    const endDate = moment().endOf('month').toDate();

    return getByDateRange(startDate, endDate);
};

export const getLedgerItems = filter => {
    return getLedgerItemsFiltered(filter);
};

export const createLedgerItemsFilter = (baseFilters, advancedFilter) => {
    const startDate = baseFilters?.startDate ? moment(baseFilters.startDate, true) : undefined;
    const endDate = baseFilters?.endDate ? moment(baseFilters.endDate, true) : undefined;

    const baseFilter = Object.fromEntries(
        Object.entries({
            startDate: startDate?.isValid() ? startDate.format(Defaults.DATE_FORMAT) : '',
            endDate: endDate?.isValid() ? endDate.format(Defaults.DATE_FORMAT) : '',
        }).filter(([, value]) => !isNullOrEmpty(value))
    );

    return { ...advancedFilter, ...baseFilter };
};

export const getAllCategories = () => {
    return getCategories();
};

export const saveCategory = category => {
    return executeSaveCategory(category);
};

export const deleteCategory = categoryId => {
    return executeDeleteCategory(categoryId);
};
