import { Button, CircularProgress, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DateFilter from 'components/filters/DateFilter';
import BalanceSummary from 'components/summaries/BalanceSummary';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setEndDate, setStartDate } from 'store/reducers/LedgerItems';
import { openMultipleAddModal } from 'store/reducers/MultipleItemsAdder';
import { openSearchModal } from 'store/reducers/Search';
import {
    getAdvancedFiltersWithMappedValues,
    getEndDate,
    getLedgerItems,
    getLedgerItemsSummary,
    getStartDate,
    showLoader,
} from 'store/selectors';
import { loadItemsData } from 'store/thunks';

import LedgerTableContent from './LedgerTableContent';
import LedgerTableFilters from './LedgerTableFilters';

const LedgerTable = () => {
    const useStyles = makeStyles(theme => ({
        root: {
            '& > *': {
                margin: theme.spacing(4),
            },
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        inlineGrid: {
            display: 'inline-grid',
        },
        margin: {
            margin: theme.spacing(1),
        },
        dateFilter: {
            textAlign: 'center',
        },
        ledgerItemsSummary: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
            maxHeight: 176,
        },
    }));

    const classes = useStyles();
    const isLoading = useSelector(showLoader);
    const ledgerItems = useSelector(getLedgerItems);
    const ledgerItemsFilters = useSelector(getAdvancedFiltersWithMappedValues);
    const ledgerItemsSummary = useSelector(getLedgerItemsSummary);
    const startDate = useSelector(getStartDate);
    const endDate = useSelector(getEndDate);

    const dispatch = useDispatch();

    const handleStartDateChange = value => {
        dispatch(setStartDate(value));
        dispatch(loadItemsData());
    };

    const handleEndDateChange = value => {
        dispatch(setEndDate(value));
        dispatch(loadItemsData());
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} className={classes.inlineGrid}>
                    <Paper className={classes.paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Actions
                        </Typography>
                        <Button
                            variant="contained"
                            component={Link}
                            color="primary"
                            to="/add"
                            className={classes.margin}>
                            Add new item
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch(openMultipleAddModal())}
                            className={classes.margin}>
                            Add multiple items
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.inlineGrid}>
                    <Paper className={classes.paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Date filter
                        </Typography>
                        <Grid container spacing={3} className={classes.dateFilter}>
                            <Grid item xs={6}>
                                <DateFilter value={startDate} onChange={handleStartDateChange} label="Start date" />
                            </Grid>
                            <Grid item xs={6}>
                                <DateFilter value={endDate} onChange={handleEndDateChange} label="End date" />
                            </Grid>
                        </Grid>
                        <Button color="primary" onClick={() => dispatch(openSearchModal())} className={classes.margin}>
                            Advanced search
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.inlineGrid}>
                    <Paper className={classes.ledgerItemsSummary}>
                        <BalanceSummary ledgerItemsSummary={ledgerItemsSummary} />
                    </Paper>
                </Grid>

                {ledgerItemsFilters && !!Object.keys(ledgerItemsFilters).length && (
                    <Grid item xs={12} className={classes.inlineGrid}>
                        <Paper className={classes.paper}>
                            <LedgerTableFilters ledgerItemsFilters={ledgerItemsFilters} />
                        </Paper>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <TableContainer component={Paper} className={classes.paper}>
                        <Typography component="h2" variant="h6" color="primary" gutterBottom>
                            Ledger items
                        </Typography>
                        {isLoading ? <CircularProgress /> : <LedgerTableContent ledgerItems={ledgerItems} />}
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    );
};

export default LedgerTable;
