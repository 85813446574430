import { configureStore } from '@reduxjs/toolkit';
import LedgerItemsMiddleware from 'middleware/LedgerItemsMiddleware';

import authenticationReducer from './reducers/Authentication';
import categoriesReducer from './reducers/Categories';
import errorSnackbarReducer from './reducers/ErrorSnackbar';
import ledgerItemsReducer from './reducers/LedgerItems';
import menuReducer from './reducers/Menu';
import multipleItemsAdderReducer from './reducers/MultipleItemsAdder';
import searchReducer from './reducers/Search';

export default configureStore({
    reducer: {
        menu: menuReducer,
        ledgerItems: ledgerItemsReducer,
        multipleItemsAdder: multipleItemsAdderReducer,
        errorSnackbar: errorSnackbarReducer,
        search: searchReducer,
        categories: categoriesReducer,
        authentication: authenticationReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['authentication/login/fulfilled'],
            },
        }).concat(LedgerItemsMiddleware),
});
