import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const CategoryDeleteModal = ({ isOpen, categoryToDelete, onClose, onClickCancelButton, handleDeleteCategory }) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Confirm category removal</DialogTitle>
            <DialogContent>
                <p>
                    Do you want to remove category <strong>{categoryToDelete?.name}</strong>?
                </p>
                <p>
                    All ledger items having the removed category will be reassigned to category
                    <strong> (no category)</strong>.
                </p>
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClickCancelButton}>
                    Cancel
                </Button>
                <Button type="submit" color="primary" onClick={handleDeleteCategory(categoryToDelete?.id)}>
                    Delete category
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CategoryDeleteModal;
