import moment from 'moment';

/**
 * Formats the Date object to given format.
 * @param {Date} date
 * @param {String} format of date
 * @returns {String} date formatted in given format, empty if one of given params is invalid
 */
export const formatDate = (date, format) => {
    return format && date ? moment(date).format(format) : '';
};
