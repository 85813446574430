import { IconButton } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Delete } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { isFulfilled } from '@reduxjs/toolkit';
import { Defaults } from 'const/Defaults';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteLedgerItem, loadItemsData } from 'store/thunks';
import { formatDate } from 'utils/DateUtils';
import { formatNumberToTwoDecimalPlaces } from 'utils/NumberUtils';

const LedgerTableItem = ({
    itemData: { id, date, vendor, description, type, count, pricePerUnit, sum, categoryName },
}) => {
    const dispatch = useDispatch();

    const handleDeleteLedgerItem = async ledgerItemId => {
        const deleteResult = await dispatch(deleteLedgerItem(ledgerItemId));

        if (isFulfilled(deleteResult)) {
            dispatch(loadItemsData());
        }
    };

    return (
        <TableRow aria-label="ledgerTableItem">
            <TableCell>{formatDate(date, Defaults.DATE_FORMAT)}</TableCell>
            <TableCell>{vendor}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>{type}</TableCell>
            <TableCell align="right">{count}</TableCell>
            <TableCell align="right">{formatNumberToTwoDecimalPlaces(pricePerUnit)}</TableCell>
            <TableCell align="right">{formatNumberToTwoDecimalPlaces(sum)}</TableCell>
            <TableCell>{categoryName}</TableCell>
            <TableCell>
                <Link to={`/${id}/edit`}>
                    <IconButton aria-label={`Edit ledger item-${id}`}>
                        <EditIcon />
                    </IconButton>
                </Link>
                <IconButton aria-label={`Delete ledger item-${id}`} onClick={() => handleDeleteLedgerItem(id)}>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default LedgerTableItem;
