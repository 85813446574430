import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { closeMenu } from 'store/reducers/Menu';
import { showMenu } from 'store/selectors';

import Menu from './Menu';

const TopBarDrawer = ({ drawerWidth }) => {
    const useStyles = makeStyles(theme => ({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        hide: {
            display: 'none',
        },
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const isShowingMenu = useSelector(showMenu);
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={isShowingMenu}
            classes={{
                paper: classes.drawerPaper,
            }}>
            <div className={classes.drawerHeader}>
                <IconButton
                    className={clsx(!isShowingMenu && classes.hide)}
                    onClick={() => dispatch(closeMenu())}
                    aria-label="Close drawer menu">
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Menu aria-label="Drawer menu" />
        </Drawer>
    );
};

export default TopBarDrawer;
