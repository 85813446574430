import { FormControl, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const InputLedgerItemFormInput = ({
    value,
    onChange,
    type,
    name,
    required,
    formControlMargin,
    inputLabelName,
    selectOptions,
    onBlur,
    isMultiline,
    autoFocus,
    isAutoComplete,
    categoriesInputValue,
    onInputChange,
    autoCompleteGetOptionLabel,
    autoCompleteGetOptionSelected,
}) => {
    const DEFAULT_FORM_CONTROL_MARGIN = 'dense';

    if (isMultiline) {
        return (
            <FormControl margin={formControlMargin ? formControlMargin : DEFAULT_FORM_CONTROL_MARGIN} fullWidth>
                <TextField label={inputLabelName} multiline value={value} onChange={onChange} name={name} />
            </FormControl>
        );
    }

    if (isAutoComplete) {
        return (
            <FormControl margin={formControlMargin ? formControlMargin : DEFAULT_FORM_CONTROL_MARGIN} fullWidth>
                <Autocomplete
                    options={Object.values(selectOptions)}
                    value={value}
                    inputValue={categoriesInputValue || ''}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    clearOnBlur={false}
                    getOptionLabel={autoCompleteGetOptionLabel}
                    getOptionSelected={autoCompleteGetOptionSelected}
                    renderInput={params => <TextField {...params} label={inputLabelName} />}
                />
            </FormControl>
        );
    }

    return (
        <FormControl margin={formControlMargin ? formControlMargin : DEFAULT_FORM_CONTROL_MARGIN} fullWidth>
            {selectOptions ? (
                <TextField
                    select
                    label={inputLabelName}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    name={name}
                    required={required}>
                    {Object.entries(selectOptions).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>
            ) : (
                <TextField
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    type={type}
                    name={name}
                    required={required}
                    label={inputLabelName}
                    autoFocus={autoFocus}
                />
            )}
        </FormControl>
    );
};

export default InputLedgerItemFormInput;
