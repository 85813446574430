import { createAction, createSlice, isRejectedWithValue } from '@reduxjs/toolkit';
import { logoutThunk, refreshTokenThunk } from 'store/thunks';

export const refreshTokenAction = createAction('authentication/refreshTokenAction');

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState: {
        loggedIn: null,
    },
    reducers: {
        setLoggedOut: state => {
            state.loggedIn = false;
        },
        setLoggedIn: state => {
            state.loggedIn = true;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(logoutThunk.fulfilled, state => {
                state.loggedIn = false;
            })
            .addCase(refreshTokenThunk.fulfilled, state => {
                state.loggedIn = true;
            })
            .addMatcher(isRejectedWithValue, (state, action) => {
                if (action.payload.httpStatus === 401) {
                    state.loggedIn = false;
                }
            });
    },
});

export const { setLoggedIn, setLoggedOut } = authenticationSlice.actions;
export default authenticationSlice.reducer;
