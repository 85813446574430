import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshTokenAction } from 'store/reducers/Authentication';
import { getLoggedIn } from 'store/selectors';

import Login from './Login';
import MainContainer from './MainContainer';
import ErrorSnackbar from './snackbars/ErrorSnackbar';

const App = () => {
    const isLoggedIn = useSelector(getLoggedIn);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(refreshTokenAction());
    }, [dispatch]);

    return (
        <>
            <ErrorSnackbar />
            {isLoggedIn === true && <MainContainer />}
            {isLoggedIn === false && <Login />}
        </>
    );
};
export default App;
