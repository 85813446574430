import { createSlice } from '@reduxjs/toolkit';
import { loadCategories } from 'store/thunks';

export const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        categories: [],
        showLoader: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(loadCategories.pending, state => {
                state.showLoader = true;
            })
            .addCase(loadCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.showLoader = false;
            });
    },
});

export default categoriesSlice.reducer;
