import { db } from './IndexedDbConfiguration';

/**
 * Counts all entries in LedgerItems table
 * @returns {(Number|Boolean)} number of entries in LedgerItems table, false if operation has failed
 */

export const count = async () => {
    try {
        return await db.ledgerItems.count();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Returns all entries in LedgerItems table
 * @returns {(Object[]|Boolean)} list of all entries in LedgerItems table, false if operation has failed
 */
export const getAll = async () => {
    try {
        return await db.ledgerItems.toArray();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Adds new ledger items to LedgerItems table
 * @param {Object[]} LedgerItem objects to add
 * @returns {(Object[]|Boolean)} list of primary keys of saved LedgerItem objects, false if operation has failed
 */
export const addAll = async ledgerItems => {
    try {
        return await db.ledgerItems.bulkAdd(ledgerItems, {
            allKeys: true,
        });
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Returns single item from LedgerItems table by id
 * @param {Number} id of object to retrieve from table
 * @returns {(Object|undefined|Boolean)} LedgerItem object if found, undefined if object is not found, false if operation has failed
 */
export const getById = async id => {
    try {
        return await db.ledgerItems.get(id);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Saves ledger item to LedgerItems table. Overwrites table entry if object with that id exists.
 * @param {Object} LedgerItem object to save
 * @returns {(Object|Boolean)} - key under which the object was stored in the table, false if operation has failed
 */
export const save = async ledgerItem => {
    try {
        return await db.ledgerItems.put(ledgerItem);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Deletes ledger item from LedgerItems table.
 * @param {Number} id - id of LedgerItem object to delete
 * @returns {Boolean} - true no matter if a record was deleted or not, false if operation has failed
 */
export const deleteById = async id => {
    try {
        return (await db.ledgerItems.delete(id)) === undefined;
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Returns ledger items by date range.
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {Object[]|Boolean} list of LedgerItem objects, false if operation has failed
 */
export const getByDateRange = async (startDate, endDate) => {
    try {
        return await db.ledgerItems.where('date').between(startDate, endDate).toArray();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Returns ledger items filtered.
 * @param {Object} filter
 * @returns {Object[]|Boolean} list of LedgerItem objects, false if operation has failed
 */
export const getLedgerItemsFiltered = async filter => {
    try {
        let result = db.ledgerItems;

        if (filter) {
            if (filter.startDate && filter.endDate) {
                result = result.where('date').between(filter.startDate, filter.endDate);
            }
            if (filter.type) {
                result = result.and(item => {
                    return item.type === filter.type;
                });
            }
        }

        return result.toArray();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

const logErrorAndReturnFalse = err => {
    console.error(err);
    return false;
};
