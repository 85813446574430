import { Table, TableBody } from '@material-ui/core';

import LedgerTableHeader from './LedgerTableHeader';
import LedgerTableItem from './LedgerTableItem';

const LedgerTableContent = ({ ledgerItems }) => {
    return (
        <Table size="small">
            <LedgerTableHeader />
            <TableBody>
                {ledgerItems?.map(ledgerItem => (
                    <LedgerTableItem key={ledgerItem.id} itemData={ledgerItem} />
                ))}
            </TableBody>
        </Table>
    );
};

export default LedgerTableContent;
