import moment from 'moment';

import { addAllLedgerItems, countLedgerItems } from './LedgerItemsService';

export const populateDatabaseWithSampleData = async () => {
    const numberOfLedgerItemsInIndexedDb = await countLedgerItems();

    if (numberOfLedgerItemsInIndexedDb === 0) {
        const response = await fetch('/sample.json');

        const sampleData = await response.json();

        if (sampleData.length) {
            sampleData.map(e => {
                e.date = moment(e.date).isValid() ? moment(e.date).toDate() : null;
                return e;
            });
            addAllLedgerItems(sampleData);
        }
    }
};
