export const ItemFields = Object.freeze({
    COUNT: {
        name: 'count',
        label: 'Count',
    },
    SUM: {
        name: 'sum',
        label: 'Total',
    },
    PRICE_PER_UNIT: {
        name: 'pricePerUnit',
        label: 'Price per unit',
    },
    DATE: {
        name: 'date',
        label: 'Date',
    },
    VENDOR: {
        name: 'vendor',
        label: 'Vendor',
    },
    DESCRIPTION: {
        name: 'description',
        label: 'Description',
    },
    TYPE: { name: 'type', label: 'Type' },
    CATEGORY: { name: 'categoryId', label: 'Category' },
});
