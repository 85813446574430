import { createAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { deleteLedgerItem, loadInitialData, loadItemsData } from '../thunks';

export const setFiltersAction = createAction('ledgerItems/setFiltersAction');
export const bootstrapAction = createAction('ledgerItems/bootstrap');

export const ledgerItemsSlice = createSlice({
    name: 'ledgerItems',
    initialState: {
        ledgerItems: [],
        showLoader: false,
        advancedFilters: {},
        baseFilters: {
            startDate: moment().startOf('month').toDate().getTime(),
            endDate: null,
        },
        summary: [],
    },
    reducers: {
        displayLoader: state => {
            state.showLoader = true;
        },
        deleteLedgerItemReducer: (state, action) => {
            state.ledgerItems = state.ledgerItems.filter(ledgerItem => ledgerItem.id !== action.payload);
        },
        setStartDate: (state, action) => {
            state.baseFilters.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.baseFilters.endDate = action.payload;
        },
        setAdvancedFilters: (state, action) => {
            action.payload
                ? (state.advancedFilters = {
                      ...action.payload,
                  })
                : (state.advancedFilters = {});
        },
    },
    extraReducers(builder) {
        builder
            .addCase(loadInitialData.fulfilled, (state, action) => {
                state.ledgerItems = action.payload;
                state.showLoader = false;
            })
            .addCase(loadItemsData.fulfilled, (state, action) => {
                state.ledgerItems = action.payload.ledgerItems;
                state.summary = action.payload.summary;
                state.showLoader = false;
            })
            .addCase(loadItemsData.pending, state => {
                state.showLoader = true;
            })
            .addCase(deleteLedgerItem.pending, state => {
                state.showLoader = true;
            })
            .addCase(deleteLedgerItem.fulfilled, state => {
                state.showLoader = false;
            });
    },
});

export const { displayLoader, deleteLedgerItemReducer, setStartDate, setEndDate, setAdvancedFilters } =
    ledgerItemsSlice.actions;

export default ledgerItemsSlice.reducer;
