import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { openMenu } from 'store/reducers/Menu';
import { getLoggedIn, showMenu } from 'store/selectors';
import { logoutThunk } from 'store/thunks';

import TopBarDrawer from './TopBarDrawer';

const TopBar = ({ drawerWidth }) => {
    const useStyles = makeStyles(theme => ({
        appBar: {
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        logoutButton: {
            marginLeft: 'auto',
        },
    }));

    const classes = useStyles();
    const dispatch = useDispatch();

    const isShowingMenu = useSelector(showMenu);
    const isLoggedIn = useSelector(getLoggedIn);

    const onClickLogoutButton = () => {
        dispatch(logoutThunk());
    };

    return (
        <>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: isShowingMenu,
                })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer menu"
                        edge="start"
                        onClick={() => dispatch(openMenu())}
                        className={clsx(classes.menuButton, isShowingMenu && classes.hide)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Personal ledger
                    </Typography>
                    {isLoggedIn && (
                        <Button className={classes.logoutButton} onClick={onClickLogoutButton} color="inherit">
                            Logout
                        </Button>
                    )}
                </Toolbar>
            </AppBar>

            <TopBarDrawer drawerWidth={drawerWidth} />
        </>
    );
};

export default TopBar;
