import MomentUtils from '@date-io/moment';
import '@fontsource/roboto';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import App from 'components/App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import store from 'store/store';

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <CssBaseline />
            <BrowserRouter>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App />
                </MuiPickersUtilsProvider>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);
reportWebVitals();
