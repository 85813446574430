import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Category } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Link } from 'react-router-dom';

const Menu = () => {
    return (
        <>
            <Divider />
            <List>
                <ListItem button component={Link} to="/">
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText>Dashboard</ListItemText>
                </ListItem>
                <ListItem button component={Link} to="/categories">
                    <ListItemIcon>
                        <Category />
                    </ListItemIcon>
                    <ListItemText>Manage categories</ListItemText>
                </ListItem>
            </List>
        </>
    );
};

export default Menu;
