const { TableHead, TableRow, TableCell, makeStyles } = require('@material-ui/core');

const CategoriesHeader = () => {
    const useStyles = makeStyles(theme => ({
        actionsColumn: {
            width: '25%',
        },
    }));

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                <TableCell>Category name</TableCell>
                <TableCell className={classes.actionsColumn}>Actions</TableCell>
            </TableRow>
        </TableHead>
    );
};

export default CategoriesHeader;
