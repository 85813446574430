import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { LedgerItemTypes } from 'const/LedgerItemTypes';
import { SearchFields } from 'const/SearchFields';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFiltersAction } from 'store/reducers/LedgerItems';
import { closeSearchModal } from 'store/reducers/Search';
import { getCategories, getCategoriesSelectBox, showSearchModal } from 'store/selectors';
import { isNullOrEmpty } from 'utils/StringUtils';

const AdvancedSearchModal = () => {
    const useStyles = makeStyles(theme => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }));

    const classes = useStyles();
    const categoriesSelections = useSelector(getCategoriesSelectBox);
    const categories = useSelector(getCategories);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const [inputData, setInputData] = useState({
        vendor: '',
        description: '',
        type: '',
        categoryIds: [],
    });

    const dispatch = useDispatch();

    const isOpen = useSelector(showSearchModal);

    const onModalClose = () => {
        dispatch(closeSearchModal());
    };

    const handleSearch = () => {
        const inputDataWithoutBlankValues = Object.fromEntries(
            Object.entries(inputData).filter(([key, value]) => {
                if (key === SearchFields.CATEGORIES.name) {
                    return value;
                }
                return !isNullOrEmpty(value);
            })
        );

        if (inputDataWithoutBlankValues.categoryIds?.length === 0) {
            delete inputDataWithoutBlankValues.categoryIds;
        }

        dispatch(setFiltersAction(inputDataWithoutBlankValues));
        dispatch(closeSearchModal());
    };

    const onChange = e => {
        setInputData({
            ...inputData,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <Dialog open={isOpen} onClose={onModalClose} scoll="paper">
            <DialogTitle>Advanced search</DialogTitle>

            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label={SearchFields.VENDOR.label}
                    name={SearchFields.VENDOR.name}
                    value={inputData.vendor}
                    fullWidth
                    onChange={onChange}
                />
                <TextField
                    margin="dense"
                    label={SearchFields.DESCRIPTION.label}
                    name={SearchFields.DESCRIPTION.name}
                    value={inputData.description}
                    fullWidth
                    onChange={onChange}
                />
                <TextField
                    select
                    fullWidth
                    label={SearchFields.TYPE.label}
                    value={inputData.type}
                    onChange={onChange}
                    name={SearchFields.TYPE.name}>
                    <MenuItem key="none" value="">
                        (empty value)
                    </MenuItem>
                    {Object.entries(LedgerItemTypes).map(([key, value]) => (
                        <MenuItem key={key} value={value}>
                            {value}
                        </MenuItem>
                    ))}
                </TextField>

                <FormControl fullWidth>
                    <InputLabel>{SearchFields.CATEGORIES.label}</InputLabel>
                    <Select
                        multiple
                        label={SearchFields.CATEGORIES.label}
                        value={inputData.categoryIds}
                        name={SearchFields.CATEGORIES.name}
                        onChange={onChange}
                        input={<Input />}
                        renderValue={selected => {
                            return (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value} label={categories[value]?.name} className={classes.chip} />
                                    ))}
                                </div>
                            );
                        }}
                        MenuProps={MenuProps}>
                        {Object.entries(categoriesSelections).map(([key, value]) => (
                            <MenuItem key={key} value={key}>
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>

            <DialogActions>
                <Button color="primary" onClick={onModalClose}>
                    Cancel
                </Button>
                <Button color="primary" onClick={handleSearch}>
                    Search
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AdvancedSearchModal;
