import { Button, CircularProgress, makeStyles, Paper, Table, TableBody, Typography } from '@material-ui/core';
import { isFulfilled } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories, getCategoriesSortedAlphabetically, showCategoriesLoader } from 'store/selectors';
import { deleteCategoryThunk, loadCategories, saveCategoryThunk } from 'store/thunks';

import CategoriesHeader from './CategoriesHeader';
import Category from './Category';
import CategoryAddModal from './CategoryAddModal';
import CategoryDeleteModal from './CategoryDeleteModal';
import CategoryEditModal from './CategoryEditModal';

const Categories = () => {
    const useStyles = makeStyles(theme => ({
        paper: {
            padding: theme.spacing(2),
            overflow: 'auto',
            width: '50%',
        },
        margin: {
            margin: theme.spacing(1),
        },
    }));

    const initialCategory = {
        name: '',
    };

    const classes = useStyles();
    const dispatch = useDispatch();

    const categoriesAlphabetically = useSelector(getCategoriesSortedAlphabetically);
    const categories = useSelector(getCategories);

    const isLoading = useSelector(showCategoriesLoader);

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState();

    const [isEditOpen, setEditOpen] = useState(false);
    const [categoryToEdit, setCategoryToEdit] = useState();

    const [isAddOpen, setAddOpen] = useState(false);
    const [categoryToAdd, setCategoryToAdd] = useState(initialCategory);

    const handleCloseModal = () => {
        setDeleteOpen(false);
        setEditOpen(false);
        setAddOpen(false);
        setCategoryToDelete();
        setCategoryToEdit();
        setCategoryToAdd(initialCategory);
    };

    const onOpenDeleteCategoryModal = categoryId => () => {
        setCategoryToDelete(categories[categoryId]);
        setDeleteOpen(true);
    };

    const handleDeleteCategory = categoryId => async () => {
        const deleteResult = await dispatch(deleteCategoryThunk(categoryId));

        if (isFulfilled(deleteResult)) {
            handleCloseModal();
            dispatch(loadCategories());
        }
    };

    const onOpenEditCategoryModal = categoryId => () => {
        setCategoryToEdit(categories[categoryId]);
        setEditOpen(true);
    };

    const handleOpenAddCategory = () => {
        setAddOpen(true);
    };

    const handleSaveCategory = async categoryToSave => {
        const saveResult = await dispatch(saveCategoryThunk(categoryToSave));

        if (isFulfilled(saveResult)) {
            handleCloseModal();
            dispatch(loadCategories());
        }
    };

    return (
        <>
            <Paper className={classes.paper}>
                <Typography component="h2" variant="h6" color="primary" gutterBottom>
                    Categories
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.margin}
                        onClick={handleOpenAddCategory}>
                        Add new category
                    </Button>
                </Typography>

                {isLoading ? (
                    <CircularProgress />
                ) : (
                    <Table size="small">
                        <CategoriesHeader />
                        <TableBody>
                            {categoriesAlphabetically.map(([key, value]) => (
                                <Category
                                    key={key}
                                    category={value}
                                    onOpenEditCategoryModal={onOpenEditCategoryModal}
                                    onOpenDeleteCategoryModal={onOpenDeleteCategoryModal}
                                />
                            ))}
                        </TableBody>
                    </Table>
                )}
            </Paper>

            <CategoryDeleteModal
                isOpen={isDeleteOpen}
                categoryToDelete={categoryToDelete}
                onClose={handleCloseModal}
                onClickCancelButton={handleCloseModal}
                handleDeleteCategory={handleDeleteCategory}
            />
            <CategoryEditModal
                isOpen={isEditOpen}
                categoryToEdit={categoryToEdit}
                onClose={handleCloseModal}
                onClickCancelButton={handleCloseModal}
                handleEditCategory={handleSaveCategory}
            />
            <CategoryAddModal
                isOpen={isAddOpen}
                categoryToAdd={categoryToAdd}
                onClose={handleCloseModal}
                onClickCancelButton={handleCloseModal}
                handleAddCategory={handleSaveCategory}
            />
        </>
    );
};

export default Categories;
