import TableCell from '@material-ui/core/TableCell';
import InputLedgerItemFormInput from 'components/singleLedgerItem/InputLedgerItemFormInput';
import { ItemFields } from 'const/ItemFields';
import { LedgerItemTypes } from 'const/LedgerItemTypes';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCategories } from 'store/selectors';
import { roundToTwoDecimalPlaces } from 'utils/NumberUtils';

const AddMultipleItemsModalRow = ({ mapKey, updateItems, item }) => {
    const [itemData, setItemData] = useState(item);
    const categories = useSelector(getCategories);
    const [categoriesInputValue, setCategoriesInputValue] = useState('');

    const onBlurCount = value => {
        const newItemData = {
            ...itemData,
            [ItemFields.COUNT.name]: value ? Number(value) : '',
            [ItemFields.SUM.name]: roundToTwoDecimalPlaces(value * itemData.pricePerUnit),
        };

        setItemData(newItemData);
        updateItems(mapKey, newItemData);
    };

    const onBlurPricePerUnit = value => {
        const pricePerUnitRounded = value ? Number(roundToTwoDecimalPlaces(value)) : '';
        const newItemData = {
            ...itemData,
            [ItemFields.PRICE_PER_UNIT.name]: pricePerUnitRounded,
            [ItemFields.SUM.name]: roundToTwoDecimalPlaces(pricePerUnitRounded * itemData.count),
        };
        setItemData(newItemData);
        updateItems(mapKey, newItemData);
    };

    const onChange = (name, value) => {
        const newItemData = { ...itemData, [name]: value };
        setItemData(newItemData);
        updateItems(mapKey, newItemData);
    };

    const onInputChangeCategory = (event, value) => {
        if (event) {
            setCategoriesInputValue(value);
        }
    };

    const onChangeCategory = (event, category) => {
        if (category) {
            onChange(ItemFields.CATEGORY.name, category.id);
        }
    };

    return (
        <>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.DATE.label}
                    value={itemData.date}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    type="date"
                    name={ItemFields.DATE.name}
                    required
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.VENDOR.label}
                    value={itemData.vendor}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    name={ItemFields.VENDOR.name}
                    autoFocus
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.DESCRIPTION.label}
                    value={itemData.description}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    name={ItemFields.DESCRIPTION.name}
                    isMultiline
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.TYPE.label}
                    value={itemData.type}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    name={ItemFields.TYPE.name}
                    selectOptions={LedgerItemTypes}
                    required
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.COUNT.label}
                    value={itemData.count}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    onBlur={e => onBlurCount(e.target.value)}
                    name={ItemFields.COUNT.name}
                    type="number"
                    required
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.PRICE_PER_UNIT.label}
                    value={itemData.pricePerUnit}
                    onChange={e => onChange(e.target.name, e.target.value)}
                    onBlur={e => onBlurPricePerUnit(e.target.value)}
                    name={ItemFields.PRICE_PER_UNIT.name}
                    type="number"
                    required
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.SUM.label}
                    value={itemData.sum}
                    name={ItemFields.SUM.name}
                    type="number"
                />
            </TableCell>
            <TableCell scope="row">
                <InputLedgerItemFormInput
                    inputLabelName={ItemFields.CATEGORY.label}
                    value={itemData.categoryId}
                    onChange={onChangeCategory}
                    name={ItemFields.CATEGORY.name}
                    selectOptions={categories}
                    categoriesInputValue={categoriesInputValue}
                    onInputChange={onInputChangeCategory}
                    isAutoComplete
                    autoCompleteGetOptionLabel={option => option.name || ''}
                    autoCompleteGetOptionSelected={(option, value) => option.id === value}
                />
            </TableCell>
        </>
    );
};

export default AddMultipleItemsModalRow;
