import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const LedgerTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell align="right">Amount</TableCell>
                <TableCell align="right">Price per unit</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
        </TableHead>
    );
};
export default LedgerTableHeader;
