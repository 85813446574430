/**
 * Rounds the Number object to two decimal places.
 * @param {Number} number
 * @returns {Number} rounded to two decimal places, 0 if the given param is invalid
 */
export const roundToTwoDecimalPlaces = number => {
    return number ? +(Math.round(number + 'e+2') + 'e-2') : 0;
};

/**
 * Formats the Number object to two decimal places.
 * @param {Number} number
 * @returns {String} number formatted to two decimal places. '0.00' if given number object is false/invalid
 */

export const formatNumberToTwoDecimalPlaces = number => {
    return number ? number.toFixed(2) : '0.00';
};
