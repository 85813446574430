import { Link, List, ListItem, ListItemText, makeStyles, Typography } from '@material-ui/core';
import { ApiParamLabels } from 'const/ApiParamLabels';
import { useDispatch } from 'react-redux';
import { setFiltersAction } from 'store/reducers/LedgerItems';

const LedgerTableFilters = ({ ledgerItemsFilters }) => {
    const useStyles = makeStyles(theme => ({
        margin: {
            margin: theme.spacing(1),
        },
        wordWrap: {
            wordWrap: 'break-word',
        },
    }));

    const dispatch = useDispatch();

    const clearAllFilters = () => {
        dispatch(setFiltersAction());
    };

    const classes = useStyles();

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom aria-label="Advanced search options">
                Advanced search options
                <Link component="button" className={classes.margin} onClick={clearAllFilters}>
                    Clear all
                </Link>
            </Typography>

            <List className={classes.wordWrap}>
                {ledgerItemsFilters &&
                    Object.entries(ledgerItemsFilters).map(([key, value]) => (
                        <ListItem key={key}>
                            <ListItemText primary={ApiParamLabels[key]} secondary={value} />
                        </ListItem>
                    ))}
            </List>
        </>
    );
};

export default LedgerTableFilters;
