import { RestApiParams } from 'const/RestApiParams';

/**
 * Retrieves ledger items from REST API by given filter.
 * @param {Object} filter
 * @returns {Promise|Boolean} promise with Ledger Item resources, false if operation has failed
 */
export const getLedgerItemsFiltered = async filter => {
    try {
        const url = new URL(RestApiParams.LEDGER_ITEMS_URL);
        Object.keys(filter).forEach(key => url.searchParams.append(key, filter[key]));
        const response = await fetch(url);

        return handleResponse(response).json();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Returns single ledger item from REST API
 * @param {Number} id of resource to retrieve
 * @returns {(Promise|Boolean)} promise with single Ledger Item resource if found, false if operation has failed
 */
export const getById = async id => {
    try {
        const response = await fetch(`${RestApiParams.LEDGER_ITEMS_URL}/${id}`);
        return handleResponse(response).json();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Saves ledger items. Overwrites entry if ledger item with given id exists.
 * @param {Object[]} LedgerItem objects array to save
 * @returns {(Promise|Boolean)} promise with saved or updated resources, false if operation has failed
 */
export const save = async ledgerItem => {
    try {
        const isUpdate = ledgerItem[0].id && ledgerItem.length === 1;
        const response = await fetch(
            isUpdate ? `${RestApiParams.LEDGER_ITEMS_URL}/${ledgerItem[0].id}` : `${RestApiParams.LEDGER_ITEMS_URL}`,
            {
                method: isUpdate ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(isUpdate ? ledgerItem[0] : ledgerItem),
            }
        );
        return isUpdate ? handleResponse(response) : handleResponse(response).json();
    } catch (err) {
        logErrorAndReturnFalse(err);
    }
};

/**
 * Deletes ledger item resource.
 * @param {Number} id - id of LedgerItem resource to delete
 * @returns {(Promise|Boolean)} - promise no matter if a record was deleted or not, false if operation has failed
 */
export const deleteById = async id => {
    try {
        const response = await fetch(`${RestApiParams.LEDGER_ITEMS_URL}/${id}`, {
            method: 'DELETE',
        });
        return handleResponse(response);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Retrieves categories from REST API
 * @param {Object} filter
 * @returns {Promise|Boolean} promise with categories resources, false if operation has failed
 */
export const getCategories = async () => {
    try {
        const response = await fetch(RestApiParams.CATEGORIES_URL);

        return handleResponse(response).json();
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Saves category. Overwrites entry if category with given id exists.
 * @param {Object} Category object to save
 * @returns {(Promise|Boolean)} promise with saved or updated resources, false if operation has failed
 */
// TODO: Rewrite the API calls logic using Axios
export const executeSaveCategory = async category => {
    try {
        const isUpdate = category?.id;
        const response = await fetch(
            isUpdate ? `${RestApiParams.CATEGORIES_URL}/${category.id}` : `${RestApiParams.CATEGORIES_URL}`,
            {
                method: isUpdate ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(category),
            }
        );
        return isUpdate ? handleResponse(response) : handleResponse(response).json();
    } catch (err) {
        logErrorAndReturnFalse(err);
    }
};

/**
 * Deletes category.
 * @param {Object} Category category to delete
 * @returns {(Promise|Boolean)} promise no matter if a record was deleted or not, false if operation has failed
 */
// TODO: Rewrite the API calls logic using Axios
export const executeDeleteCategory = async categoryId => {
    try {
        const response = await fetch(`${RestApiParams.CATEGORIES_URL}/${categoryId}`, {
            method: 'DELETE',
        });
        return handleResponse(response);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Login endpoint.
 * @param {Object} Credentials login and password to send in the paylaoad
 * @returns {(Promise|Boolean)} Promise during the request, false if login has failed for any reason
 */
export const login = async credentials => {
    try {
        const response = await fetch(`${RestApiParams.LOGIN_URL}`, {
            method: 'POST',
            body: JSON.stringify(credentials),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return handleResponse(response);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Logout endpoint.
 * @returns {(Promise|Boolean)} Promise during the request, false if login has failed for any reason
 */
export const logout = async () => {
    try {
        const response = await fetch(`${RestApiParams.LOGOUT_URL}`, {
            method: 'POST',
        });
        return handleResponse(response);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

/**
 * Refresh token endpoint.
 * @returns {(Promise|Boolean)} Promise during the request, false if login has failed for any reason
 */
export const refreshToken = async () => {
    try {
        const response = await fetch(`${RestApiParams.REFRESH_TOKEN_URL}`);
        return handleResponse(response);
    } catch (err) {
        return logErrorAndReturnFalse(err);
    }
};

const handleResponse = response => {
    if (response.ok) {
        return response;
    }

    const error = new Error('Call to API has returned an error');
    error.response = response;
    throw error;
};

const logErrorAndReturnFalse = err => {
    console.error(err);
    throw err;
};
