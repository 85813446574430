import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        show: false,
    },
    reducers: {
        openSearchModal: state => {
            state.show = true;
        },
        closeSearchModal: state => {
            state.show = false;
        },
    },
});

export const { openSearchModal, closeSearchModal } = searchSlice.actions;

export default searchSlice.reducer;
