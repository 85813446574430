import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { bootstrapAction } from 'store/reducers/LedgerItems';
import { showMenu } from 'store/selectors';

import AddMultipleItemsModal from './addMultipleLedgerItems/AddMultipleItemsModal';
import Categories from './categories/Categories';
import LedgerTable from './ledgerTable/LedgerTable';
import TopBar from './menu/TopBar';
import AdvancedSearchModal from './search/AdvancedSearchModal';
import InputLedgerItem from './singleLedgerItem/InputLedgerItem';

const MainContainer = () => {
    const drawerWidth = 240;

    const useStyles = makeStyles(theme => ({
        root: {
            display: 'flex',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
            overflow: 'auto',
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
    }));

    const classes = useStyles();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(bootstrapAction());
    }, [dispatch]);

    const isShowingMenu = useSelector(showMenu);

    return (
        <div className={classes.root}>
            <TopBar drawerWidth={drawerWidth} />

            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: isShowingMenu,
                })}>
                <div className={classes.drawerHeader} />
                <Switch>
                    <Route exact path="/">
                        <LedgerTable />
                    </Route>
                    <Route path="/add">
                        <InputLedgerItem />
                    </Route>
                    <Route path="/:ledgerItemId/edit">
                        <InputLedgerItem />
                    </Route>
                    <Route path="/categories">
                        <Categories />
                    </Route>
                </Switch>
                <AddMultipleItemsModal />
                <AdvancedSearchModal />
            </main>
        </div>
    );
};
export default MainContainer;
