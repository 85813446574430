import {
    Button,
    Container,
    CssBaseline,
    FormControl,
    FormHelperText,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core';
import { isFulfilled } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoggedIn } from 'store/reducers/Authentication';
import { loginThunk } from 'store/thunks';

const Login = () => {
    const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }));

    const classes = useStyles();
    const [credentials, setCredentials] = useState({
        login: '',
        password: '',
    });
    const [helperText, setHelperText] = useState('');
    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const onChange = e => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();

        setHelperText('');
        setError(false);

        if (!credentials.login || !credentials.password) {
            setHelperText('Please provide login and password');
            setError(true);
        } else {
            const loginResult = await dispatch(loginThunk(credentials));

            if (isFulfilled(loginResult)) {
                dispatch(setLoggedIn());
            } else if (loginResult.payload?.httpStatus === 401) {
                setHelperText('Invalid login and password');
                setError(true);
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} noValidate onSubmit={handleSubmit}>
                    <FormControl fullWidth error={error}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            label="Login"
                            name="login"
                            autoComplete="login"
                            autoFocus
                            inputProps={{
                                'aria-label': 'login',
                            }}
                            value={credentials.login}
                            onChange={onChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            name="password"
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            inputProps={{
                                'aria-label': 'password',
                            }}
                            value={credentials.password}
                            onChange={onChange}
                        />
                        <FormHelperText>{helperText}</FormHelperText>
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                            Sign In
                        </Button>
                    </FormControl>
                </form>
            </div>
        </Container>
    );
};

export default Login;
