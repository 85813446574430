import Dexie from 'dexie';

class IndexedDbConfiguration extends Dexie {
    constructor() {
        super('personal-ledger');
        this.version(1).stores({
            ledgerItems: 'id++,date,vendor,description,type,count,pricePerUnit,sum,category',
        });
    }
}

export const db = new IndexedDbConfiguration();
