import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const AddMultipleItemsModalCancelModal = ({ isOpen, onClickCancelButton, toggleCancelModal }) => {
    return (
        <Dialog open={isOpen} onClose={() => toggleCancelModal(false)}>
            <DialogTitle>Confirm cancelation</DialogTitle>
            <DialogContent>Are you sure you want cancel? All data put in the table will be lost.</DialogContent>

            <DialogActions>
                <Button onClick={() => toggleCancelModal(false)} color="primary">
                    No
                </Button>
                <Button onClick={onClickCancelButton} color="primary">
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddMultipleItemsModalCancelModal;
