import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { isFulfilled } from '@reduxjs/toolkit';
import { Defaults } from 'const/Defaults';
import { LedgerItemTypes } from 'const/LedgerItemTypes';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeMultipleAddModal } from 'store/reducers/MultipleItemsAdder';
import { showAddMultipleItemsModal } from 'store/selectors';
import { loadItemsData, saveLedgerItems } from 'store/thunks';
import { v4 as uuidv4 } from 'uuid';

import AddMultipleItemsModalCancelModal from './AddMultipleItemsModalCancelModal';
import AddMultipleItemsModalRow from './AddMultipleItemsModalRow';
import AddMultipleItemsModalSummary from './AddMultipleItemsModalSummary';

const AddMultipleItemsModal = () => {
    const useStyles = makeStyles(() => ({
        form: {
            display: 'contents',
        },
        total: {
            width: '10%',
        },
    }));

    const isOpen = useSelector(showAddMultipleItemsModal);
    const [isCancelConfirmationModalOpen, setIsCancelConfirmationModalOpen] = useState(false);

    const dispatch = useDispatch();
    const classes = useStyles();

    const initialData = [
        uuidv4(),
        {
            date: moment().format(Defaults.DATE_FORMAT),
            vendor: '',
            description: '',
            type: LedgerItemTypes.EXPENSE,
            count: '',
            pricePerUnit: '',
            sum: '',
            categoryId: Defaults.CATEGORY_ID,
        },
    ];

    const [items, setItems] = useState(new Map([initialData]));
    const [lastItemId, setLastItemId] = useState(Array.from(items.keys()).pop());
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalIncomes, setTotalIncomes] = useState(0);

    const handleSubmit = e => {
        e.preventDefault();
        handleSaveLedgerItems(items);
    };

    const onClickCancelButton = () => {
        if (!isCancelConfirmationModalOpen && items.size > 1) {
            setIsCancelConfirmationModalOpen(true);
        } else {
            dispatch(closeMultipleAddModal());
            setItems(new Map([initialData]));
            setLastItemId(initialData[0]);
            setTotalIncomes(0);
            setTotalExpenses(0);
            setIsCancelConfirmationModalOpen(false);
        }
    };

    const onClickPlus = e => {
        const gen = uuidv4();
        const map = items;
        map.set(gen, initialData[1]);

        setItems(map);
        setLastItemId(gen);
    };

    const onClickMinus = id => {
        const mapCopy = new Map(items);
        mapCopy.delete(id);

        setItems(mapCopy);
        setLastItemId(Array.from(mapCopy.keys()).pop());
        calculateTotals(mapCopy);
    };

    const updateItems = (key, item) => {
        items.set(key, item);

        calculateTotals(items);
        setItems(items);
    };

    const calculateTotals = items => {
        let totalExpenses = 0;
        let totalIncomes = 0;

        items.forEach(e => {
            if (e.type === LedgerItemTypes.EXPENSE) {
                totalExpenses += Number(e.sum);
            } else {
                totalIncomes += Number(e.sum);
            }
        });

        setTotalExpenses(totalExpenses);
        setTotalIncomes(totalIncomes);
    };

    const onModalClose = () => {
        dispatch(closeMultipleAddModal());
    };

    const toggleCancelModal = open => {
        setIsCancelConfirmationModalOpen(open);
    };

    const handleSaveLedgerItems = async ledgerItems => {
        const saveResult = await dispatch(saveLedgerItems([...ledgerItems.values()]));

        if (isFulfilled(saveResult)) {
            setItems(new Map([initialData]));
            setLastItemId(initialData[0]);
            setTotalIncomes(0);
            setTotalExpenses(0);
            dispatch(closeMultipleAddModal());
            dispatch(loadItemsData());
        }
    };

    return (
        <Dialog open={isOpen} onClose={onModalClose} maxWidth={false} fullWidth={true} scoll="paper">
            <form onSubmit={handleSubmit} className={classes.form}>
                <DialogTitle>Add multiple items</DialogTitle>

                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {[...items.keys()].map((key, idx) => (
                                    <TableRow key={key} data-testId="mutliple-add-row">
                                        <TableCell scope="row"># {idx + 1}</TableCell>
                                        <AddMultipleItemsModalRow
                                            mapKey={key}
                                            item={items.get(key)}
                                            updateItems={updateItems}
                                        />
                                        <TableCell scope="row">
                                            {lastItemId === key && (
                                                <IconButton onClick={onClickPlus} aria-label="Add new row">
                                                    <AddCircle />
                                                </IconButton>
                                            )}

                                            {items.size > 1 && (
                                                <IconButton onClick={() => onClickMinus(key)} aria-label="Remove row">
                                                    <RemoveCircle />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <DialogActions>
                    <AddMultipleItemsModalSummary totalIncomes={totalIncomes} totalExpenses={totalExpenses} />

                    <Button onClick={onClickCancelButton} color="primary">
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                </DialogActions>

                <AddMultipleItemsModalCancelModal
                    isOpen={isCancelConfirmationModalOpen}
                    onClickCancelButton={onClickCancelButton}
                    toggleCancelModal={toggleCancelModal}
                />
            </form>
        </Dialog>
    );
};

export default AddMultipleItemsModal;
