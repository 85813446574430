import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import InputLedgerItemFormInput from 'components/singleLedgerItem/InputLedgerItemFormInput';
import { ItemFields } from 'const/ItemFields';
import { useEffect, useState } from 'react';

const CategoryEditModal = ({ isOpen, categoryToEdit, onClose, onClickCancelButton, handleEditCategory }) => {
    const [category, setCategory] = useState();

    const handleSubmit = e => {
        e.preventDefault();
        handleEditCategory(category);
    };

    const onChange = e => {
        setCategory({
            ...category,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (categoryToEdit) {
            setCategory(categoryToEdit);
        }
    }, [categoryToEdit]);

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>Edit category</DialogTitle>
                <DialogContent>
                    <InputLedgerItemFormInput
                        inputLabelName={ItemFields.CATEGORY.label}
                        name="name"
                        required
                        value={category?.name}
                        onChange={onChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={onClickCancelButton}>
                        Cancel
                    </Button>
                    <Button type="submit" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CategoryEditModal;
