export const SearchFields = Object.freeze({
    DESCRIPTION: {
        name: 'description',
        label: 'Description',
    },
    TYPE: {
        name: 'type',
        label: 'Type',
    },
    CATEGORIES: {
        name: 'categoryIds',
        label: 'Categories',
    },
    VENDOR: {
        name: 'vendor',
        label: 'Vendor',
    },
});
