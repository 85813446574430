import { createSlice } from '@reduxjs/toolkit';

export const multipleItemsAdderSlice = createSlice({
    name: 'multipleItemsAdder',
    initialState: {
        show: false,
    },
    reducers: {
        openMultipleAddModal: state => {
            state.show = true;
        },
        closeMultipleAddModal: state => {
            state.show = false;
        },
    },
});

export const { openMultipleAddModal, closeMultipleAddModal } = multipleItemsAdderSlice.actions;

export default multipleItemsAdderSlice.reducer;
